body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ventory-datepicker {
  display: inline-flex;
  margin-top: 0 !important;
  margin-left: 0 !important;
}

.ventory-datepicker .MuiTextField-root {
  width: 30ch;
}

.add-item-container form.MuiBox-root {
  margin-bottom: 20px;
}

.active-nav {
  background: #0275d8 !important;
}

.out-of-stock {
  background-color: #6c757d !important;
}

.out-of-stock th,
.out-of-stock td {
  color: #222 !important;
}

.ventory-table-head th {
  background-color: #111 !important;
}

.ventory-drawer > div {
  background-color: #000 !important;
}

.ventory-app-bar > div {
  background-color: #111 !important;
}

.react-select__menu-list {
  background-color: #1e1e1e;
}

.react-select__input {
  color: #fff !important;
}

.clear-cache-btn {
  margin-top: 30px !important;
}

.ventory-filter {
  margin-top: 20px;
}

.ventory-filter > div {
  padding: 3px !important;
}

.filter-toggle {
  display: block;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: bold;
}

.filter-wrapper {
  padding-top: 5px !important;
}

.muted {
  color: #999;
}

.config-text {
  margin-bottom: 10px;
  display: block;
  color: #e2f4ad;
}

.tags-on-table {
  margin-top: 10px;
}

.tags-on-table .MuiChip-root {
  font-size: 12px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.tags-on-table .MuiChip-root .MuiChip-label {
  padding: 0 5px;
}

.select__menu {
  z-index: 99999 !important;
}

.tags-label {
  font-size: 12px;
}
